<template>
  <div>
    <v-card outlined class="box-shadow">
      <v-card-text>
        <LineChart v-bind="barChartProps" />
        <img style="width: 300px" v-if="imgData" :src="imgData" />

        <div class="mt-5 d-flex align-center flex-wrap">
          <div>
            <span class="font-weight-bold">Total Users:</span> {{ users.all.length }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import db from '@/firebase/init'
import { Chart, registerables } from "chart.js"
import { LineChart, useBarChart } from "vue-chart-3"
import { ref, computed, defineComponent, reactive, onMounted } from "@vue/composition-api"

Chart.register(...registerables)

export default defineComponent ({
  name: 'UserStatistics',

  metaInfo: {
    title: 'Users Statistics'
  },

  components: {
    LineChart,
  },

  setup() {
    const imgData = ref(null)
    const users = reactive({ all: [] })
    const month =  ref(moment().format('M'))
    const year =  ref(moment().format('YYYY'))
    
    const options = computed(() => ({
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: moment().format('MMMM YYYY'),
        },
      },
    }))

    async function getUsers() {
      await db.collection('users')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let user = doc.data()
          user.id = doc.id
          user.ref = doc.ref
          users.all.push(user)
        })
      })
      .catch(error => {
        console.log(error.message)
      })
    }

    const values = computed(() => {
      let arr = []

        for (var i = 1; i <= parseInt(moment().format('D')); i++) {
          let count = users.all.filter(u => {
            let created = typeof u.createdAt == 'object' ? u.createdAt.toDate() : u.createdAt
            return parseInt(moment(created).format('D')) == parseInt(i) &&
                   parseInt(moment(created).format('YYYY')) == parseInt(year.value) &&
                   parseInt(moment(created).format('M')) == parseInt(month.value)
          })

          arr.push(count.length)
        }
  
        return arr
    })


    const chartData = computed(() => ({
      labels: Array.from({ length: moment(moment().format('YYYY-MM'), 'YYYY-MM').daysInMonth() }, (_, i) => i + 1 ),
      datasets: [
        {
          data: values.value,
          label: 'registrations',
          backgroundColor: ["#283f97"],
        },
      ],
    }))

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
      options,
    })

    onMounted(() => {
      getUsers()
    })

    return { getUsers, users, values, month, year, barChartProps, barChartRef, imgData }
  },
})
</script>